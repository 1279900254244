<template>
  <div v-if="isLoaded">
    <p>{{ practiceInfo.name }}</p>
    <p>Num Providers: {{ providers.length }}</p>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Linking Code</th>
          <th>Invite Date</th>
          <th>Last Modified</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="provider in providers" :key="provider.id">
          <td>{{ provider.firstName }} {{ provider.lastName }}</td>
          <td>{{ provider.email }}</td>
          <td>{{ provider.linkingCode }}</td>
          <td>{{ provider.createdDateFormat }}</td>
          <td>{{ provider.lastModifiedDateFormat }}</td>
        </tr>
      </tbody>
    </table>
    <router-link
      v-if="practiceInfo.id !== undefined"
      :to="{ name: 'CreateProvider', params: { id: practiceInfo.id } }"
      >New Provider</router-link
    >
  </div>
  <div v-else><p>Loading</p></div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { Practice } from "@/store/Practice";
import { Provider } from "@/store/Provider";
import { computed } from "vue";
export default {
  props: {
    id: String,
  },
  setup(props: any) {
    const store = useStore();
    const practiceInfo = computed(
      () => store.state.practices.filter((p: Practice) => p.id == props.id)[0]
    );

    const providers = computed(() =>
      store.state.providers.filter((p: Provider) => p.practiceId == props.id)
    );

    const isLoaded = computed(
      () =>
        store.state.practices.filter((p: Practice) => p.id == props.id).length >
        0
    );

    return { practiceInfo, providers, isLoaded };
  },
};
</script>

<style></style>
