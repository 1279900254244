
import { useStore } from "vuex";
import { Practice } from "@/store/Practice";
import { Provider } from "@/store/Provider";
import { computed } from "vue";
export default {
  props: {
    id: String,
  },
  setup(props: any) {
    const store = useStore();
    const practiceInfo = computed(
      () => store.state.practices.filter((p: Practice) => p.id == props.id)[0]
    );

    const providers = computed(() =>
      store.state.providers.filter((p: Provider) => p.practiceId == props.id)
    );

    const isLoaded = computed(
      () =>
        store.state.practices.filter((p: Practice) => p.id == props.id).length >
        0
    );

    return { practiceInfo, providers, isLoaded };
  },
};
